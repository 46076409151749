<template>
	<v-app>
		<!-- header -->
		<component v-if="headerComponent" :is="headerComponent" />
		<!-- mobile nav -->
		<component v-if="navigationComponent" :is="navigationComponent" />
		<!-- content -->
		<v-main>
			<router-view />
		</v-main>
		<!-- footer -->
		<component v-if="footerComponent" :is="footerComponent" />
		<!-- notifications -->
		<NoteDialog />
		<NoteSnackbar />
	</v-app>
</template>

<script>
import { mapState } from 'vuex'
import MobileNavigationWebsite from '@/components/MobileNavigationWebsite'
import NoteDialog from '@/modules/note/components/dialog'
import NoteSnackbar from '@/modules/note/components/snackbar'
import HeaderWebsite from '@/components/HeaderWebsite'
import FooterWebsite from '@/components/FooterWebsite'
import HeaderNovalisCircle from '@/components/HeaderNovalisCircle'
import MobileNavigationNovalisCircle from '@/components/MobileNavigationNovalisCircle'
import FooterNovalisCircle from '@/components/FooterNovalisCircle'
import HeaderCranialSymposium from '@/components/HeaderCranialSymposium'
import MobileNavigationCranialSymposium from '@/components/MobileNavigationCranialSymposium'
import FooterCranialSymposium from '@/components/FooterCranialSymposium'
import HeaderSpineSymposium from '@/components/HeaderSpineSymposium'
import MobileNavigationSpineSymposium from '@/components/MobileNavigationSpineSymposium'
import FooterSpineSymposium from '@/components/FooterSpineSymposium'
import HeaderAspac from '@/components/HeaderAspac.vue'
import MobileNavigationAspac from '@/components/MobileNavigationAspac'
import FooterAspac from '@/components/FooterAspac.vue'

export default {
	name: 'App',
	components: {
		FooterNovalisCircle,
		MobileNavigationNovalisCircle,
		HeaderNovalisCircle,
		FooterCranialSymposium,
		MobileNavigationCranialSymposium,
		HeaderCranialSymposium,
		FooterWebsite,
		MobileNavigationWebsite,
		HeaderWebsite,
		NoteSnackbar,
		NoteDialog,
		HeaderAspac,
		MobileNavigationAspac,
		FooterAspac,
		HeaderSpineSymposium,
		MobileNavigationSpineSymposium,
		FooterSpineSymposium,
	},
	computed: {
		...mapState(['page']),
		headerComponent() {
			if (this.page.settings) {
				if (this.page.settings.header) {
					return `header-${this.page.settings.theme}`
				}
				return ''
			} else {
				return 'header-website'
			}
		},
		navigationComponent() {
			if (this.page.settings) {
				if (this.page.settings.header) {
					return `mobile-navigation-${this.page.settings.theme}`
				}
				return ''
			} else {
				return 'mobile-navigation-website'
			}
		},
		footerComponent() {
			if (this.page.settings) {
				if (this.page.settings.footer) {
					return `footer-${this.page.settings.theme}`
				}
				return ''
			} else {
				return 'footer-website'
			}
		},
	},
}
</script>

<style>
html {
	-webkit-font-smoothing: initial;
	-moz-osx-font-smoothing: auto;
}

body {
	font-size: 1rem;
}

a {
	font-weight: 500;
}

small,
.small {
	font-size: 80%;
	font-weight: 400;
}

.bg-white {
	background-color: #fff !important;
}

.vh-50 {
	min-height: 50vh !important;
}
.vh-75 {
	min-height: 75vh !important;
}

.cell-content.flex-column.flex-row-important {
	flex-direction: row !important;
}

@media (min-width: 1460px) {
	.container:not(.container--fluid) {
		max-width: 1298px;
	}
}
</style>
